"use client";
import { useState } from "react";
import { Popover, Text } from "@mantine/core";
import DestinationTab from "./DestinationTab";
import ExperienceTab from "./ExperienceTab";
import ChevDown from "../icons/chevdown";
import ChevUp from "../icons/chevup";
import UserMenu from "./HeaderMenu";
import HeaderGeoSearch from "./HeaderGeoSearch";
import { TabButtonKT } from "../resuables/Button";
import ToggleTheme from "../darktheme/ToggleTheme";
import CurrencySelector from "./CurrencySelector";
import { miniIconSize } from "../../utils/iconsize";
import styles from "./header.module.css";

export default function Header() {
    const [openedD, setDOpened] = useState(false);
    const [openedE, setEOpened] = useState(false);

    const handleDestinationSelect = () => {
        setDOpened(!openedD);
        setEOpened(false);
    };

    const handleExperienceSelect = () => {
        setDOpened(false);
        setEOpened(!openedE);
    };

    return (
        <header className={styles.header}>
            <Text
                className={styles.logotext}
                onClick={() => (window.location.href = "/")}
            >
                Kodama Travel
            </Text>

            <Popover
                position="bottom"
                withArrow
                opened={openedD}
                onChange={setDOpened}
                classNames={{ dropdown: styles.popoverdropdown }}
            >
                <Popover.Target>
                    <TabButtonKT
                        visibleFrom="sm"
                        variant="subtle"
                        onClick={handleDestinationSelect}
                        rightSection={
                            openedD ? (
                                <ChevDown
                                    height={miniIconSize.width}
                                    width={miniIconSize.height}
                                    size={miniIconSize.size}
                                />
                            ) : (
                                <ChevUp
                                    height={miniIconSize.width}
                                    width={miniIconSize.height}
                                    size={miniIconSize.size}
                                />
                            )
                        }
                    >
                        Destination
                    </TabButtonKT>
                </Popover.Target>
                <Popover.Dropdown>
                    <DestinationTab />
                </Popover.Dropdown>
            </Popover>

            <Popover
                width={700}
                position="bottom"
                withArrow
                shadow="sm"
                opened={openedE}
                onChange={setEOpened}
                classNames={{ dropdown: styles.popoverdropdown }}
            >
                <Popover.Target>
                    <TabButtonKT
                        visibleFrom="sm"
                        onClick={handleExperienceSelect}
                        variant="subtle"
                        rightSection={
                            openedE ? (
                                <ChevDown
                                    height={miniIconSize.width}
                                    width={miniIconSize.height}
                                    size={miniIconSize.size}
                                />
                            ) : (
                                <ChevUp
                                    height={miniIconSize.width}
                                    width={miniIconSize.height}
                                    size={miniIconSize.size}
                                />
                            )
                        }
                    >
                        Experience
                    </TabButtonKT>
                </Popover.Target>

                <Popover.Dropdown>
                    <ExperienceTab />
                </Popover.Dropdown>
            </Popover>

            <TabButtonKT
                visibleFrom="md"
                onClick={() => (window.location.href = "/collection")}
                variant="subtle"
            >
                Collection
            </TabButtonKT>
            <TabButtonKT
                visibleFrom="md"
                onClick={() => (window.location.href = "/custom_tour")}
                variant="subtle"
            >
                Tailor-made Tour
            </TabButtonKT>

            <section className={styles.user_menu}>
                <TabButtonKT
                    onClick={() => (window.location.href = "/conservation")}
                    visibleFrom="lg"
                >
                    Conservation
                </TabButtonKT>

                <CurrencySelector />
                <HeaderGeoSearch />
                <ToggleTheme />
                <UserMenu />
            </section>
        </header>
    );
}
