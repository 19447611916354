import { Skeleton } from "@mantine/core";
import styles from "./resuables.module.css";

export default function SkeletonLoaderHero() {
    return (
        <div>
            <Skeleton
                height={320}
                classNames={{ root: styles.skeletonheroroot }}
            />
        </div>
    );
}

export function SkeletonLoaderPage() {
    return (
        <div>
            <Skeleton
                height={15}
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={15}
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={15}
                width="70%"
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={0.1}
                animate={false}
                classNames={{ root: styles.skeletonpageholderroot }}
            />
        </div>
    );
}

export function SkeletonLoaderCard() {
    return (
        <div>
            <Skeleton
                height={210}
                width="28%"
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={25}
                width="28%"
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={25}
                width="28%"
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={25}
                width="28%"
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={0.1}
                width="1%"
                mt="145px"
                classNames={{ root: styles.skeletonpageroot }}
            />
        </div>
    );
}
export function SmallSkeletonLoaderCard() {
    return (
        <div>
            <Skeleton
                height={120}
                width="20%"
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={20}
                width="20%"
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={20}
                width="20%"
                classNames={{ root: styles.skeletonpageroot }}
            />
        </div>
    );
}

export function SkeletonLoaderCardGroup() {
    return (
        <>
            <div>
                <Skeleton
                    height={25}
                    width="50%"
                    classNames={{ root: styles.skeletonpageroot }}
                />

                <Skeleton
                    height={210}
                    width="20%"
                    classNames={{ root: styles.skeletonpageroot }}
                />
                <Skeleton
                    height={20}
                    width="20%"
                    classNames={{ root: styles.skeletonpageroot }}
                />
                <Skeleton
                    height={20}
                    width="20%"
                    classNames={{ root: styles.skeletonpageroot }}
                />
                <Skeleton
                    height={20}
                    width="20%"
                    classNames={{ root: styles.skeletonpageroot }}
                />
                <Skeleton
                    height={0.1}
                    width="1%"
                    mt="160px"
                    classNames={{ root: styles.skeletonpageroot }}
                />
            </div>
            <div>
                <Skeleton
                    height={25}
                    width="50%"
                    classNames={{ root: styles.skeletonpageroot }}
                />

                <Skeleton
                    height={210}
                    width="20%"
                    classNames={{ root: styles.skeletonpageroot }}
                />
                <Skeleton
                    height={20}
                    width="20%"
                    classNames={{ root: styles.skeletonpageroot }}
                />
                <Skeleton
                    height={20}
                    width="20%"
                    classNames={{ root: styles.skeletonpageroot }}
                />
                <Skeleton
                    height={20}
                    width="20%"
                    classNames={{ root: styles.skeletonpageroot }}
                />
                <Skeleton
                    height={0.1}
                    width="1%"
                    mt="160px"
                    classNames={{ root: styles.skeletonpageroot }}
                />
            </div>
            <Skeleton
                height={25}
                width="50%"
                mt="40px"
                classNames={{ root: styles.skeletonpageroot }}
            />
        </>
    );
}
export function SkeletonLoaderExpPage() {
    return (
        <div className={styles.exppageskeleton}>
            <Skeleton
                height={35}
                width="50%"
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={250}
                width="30%"
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={15}
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={15}
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={15}
                width="70%"
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={15}
                width="70%"
                classNames={{ root: styles.skeletonpageroot }}
            />
            <Skeleton
                height={0.1}
                width="1%"
                animate={false}
                classNames={{ root: styles.skeletonpageholderroot }}
            />
        </div>
    );
}
